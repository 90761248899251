import React from "react";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";

export default function Info() {
  const { t } = useTranslation("home");

  return (
    <section
      aria-label="Info section"
      className="relative py-24 dark:bg-jacarta-900"
    >
      <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <img
          src="/images/gradient_light.jpg"
          alt="from sofia to plovdiv, from sofia to bansko transfer, bansko to sofia taxi transfer"
          className="h-full w-full"
        />
      </picture>
      <div className="container">
        <div className="lg:flex lg:justify-between">
          {/* <!-- Image --> */}
          <div className="relative lg:w-[55%]">
            <Image
              src="/images/patterns/pattern_circle_1.png"
              className="absolute -bottom-0 -left-8 animate-fly dark:opacity-10"
              alt="sofia airport to city center, sofia to sunny beach, sofia to varna, sofia to borovets transfer"
              loading="lazy"
              layout="fill"
            />
            <Image
              src="/images/patterns/pattern_circle_2.png"
              className="absolute -top-14 right-0 animate-fly dark:opacity-10 md:-right-12"
              alt="sofia to bucharest, sofia to borovets transfer, from sofia to plovdiv transfer, sofia to belgrade transfer"
              loading="lazy"
              layout="fill"
            />
            <div className="flex items-center space-x-7">
              <figure className="relative">
                <Image
                  width={320}
                  height={470}
                  src="/images/about/travel_1.webp"
                  alt="borovets to sofia transfer, sofia to borovets, sofia to istanbul transfer"
                  className="rounded-3xl"
                />
              </figure>
              <figure className="relative">
                <Image
                  width={320}
                  height={470}
                  src="/images/about/travel_2.webp"
                  alt="sofia to sunny beach, sofia airport to varna transfer, sofia to thessaloniki, sofia to bansko transfer"
                  className="rounded-3xl"
                />
              </figure>
            </div>
          </div>

          {/* <!-- Info --> */}
          <div className="py-10 lg:w-[45%] lg:pl-28">
            <h2
              aria-label="Info section title"
              className="mb-6 font-display text-3xl text-jacarta-700 dark:text-white"
            >
              {t("info-section.title")}
            </h2>
            <p
              aria-label="Info section sub title"
              className="mb-8 text-lg leading-normal dark:text-jacarta-300"
            >
              {t("info-section.sub-title")}
            </p>
            <div className="mb-8 flex space-x-4 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-8 w-8 shrink-0 fill-accent"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
              </svg>
              <div>
                <span
                  aria-label="Info section first benefit"
                  className="block font-display text-base font-semibold text-jacarta-700 dark:text-white"
                >
                  {t("info-section.benefit-one-title")}
                </span>
              </div>
            </div>
            <div className="flex space-x-4 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-8 w-8 shrink-0 fill-accent"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
              </svg>
              <div>
                <span
                  aria-label="Info section second benefit"
                  className="block font-display text-base font-semibold text-jacarta-700 dark:text-white"
                >
                  {t("info-section.benefit-two-title")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
